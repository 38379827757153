<template>

    <ul class="nav justify-content-end">
        <li v-if="showChooseRestaurant" class="nav-item">
            <a class="nav-link"
               data-bs-target="#chooseRestaurant"
               data-bs-toggle="modal"
               href="#">
                <span v-if="!authStore.restaurant">
                    Choose restaurant
                </span>
                <span v-else>
                    {{ selectedRestaurant[0].name }}
                </span>
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link" href="#" @click.prevent="logout">Logout</a>
        </li>
    </ul>
</template>

<script>
import router from "@/router";
import {useAuthStore} from "@/store/authStore";

export default {
    name: "MainNav",
    props: [
        'showChooseRestaurant',
    ],
    data() {
        const authStore = useAuthStore();
        return {
            authStore,
        }
    },
    computed: {
        selectedRestaurant() {
            let selected = {};
            if (this.authStore.restaurant) {
                selected = this.authStore.restaurants.filter(restaurant => restaurant.id === this.authStore.restaurant);
            }
            return selected;
        }
    },
    methods: {
        logout() {
            this.authStore.username = null
            this.authStore.restaurants = null
            this.authStore.restaurant = null
            localStorage.clear();
            router.push('/login');
        }
    }
}
</script>

<style scoped>

</style>