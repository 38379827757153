import {defineStore} from 'pinia'
import router from '@/router'

export const useAuthStore = defineStore('authStore', {
    state: () => {
        return {
            username: localStorage.getItem('username'),
            restaurants: JSON.parse(localStorage.getItem('restaurants')),
            restaurant: localStorage.getItem('restaurant'),
            token: localStorage.getItem('token'),
        }
    },
    actions: {
        async login(username, password) {
            return new Promise((resolve, reject) => {
                if (username === '' || password === '') {
                    return reject('Both username and password are required')
                } else {
                    fetch(process.env.VUE_APP_API_URL + 'login.php', {
                        method: 'post',
                        body: JSON.stringify({username: username, password: password}),
                        headers: {
                            'Content-type': 'application/json'
                        }
                    }).then(answer => answer.json())
                        .then(answer => {
                            if (answer.error === false) {
                                this.username = answer.msg.username;
                                this.token = answer.msg.token;
                                
                                localStorage.setItem('username', answer.msg.username);
                                localStorage.setItem('token', answer.msg.token);
                                localStorage.setItem('restaurants', JSON.stringify(answer.msg.restaurants));

                                this.restaurants = answer.msg.restaurants;

                                if (answer.msg.restaurants.length === 1) {
                                    this.restaurant = answer.msg.restaurants[0].id;
                                    localStorage.setItem('restaurant', answer.msg.restaurants[0].id);
                                }

                                router.push('/home')
                                //return resolve(JSON.stringify({'auth': true}));
                            } else {
                                return reject('Invalid username or password');
                            }
                        }).catch(() => {
                        return reject('A fatal error occurred, please contact support');
                    })
                }
            })
        },

        setRestaurant(idRestaurant) {
            this.restaurant = idRestaurant;
            localStorage.setItem('restaurant', idRestaurant);
        }
    }
})