<template>
    <div id="loader" class="border" style="display: none">
        <div class="spinner-grow text-danger" role="status">

        </div>
    </div>

    <header v-if="authStore.username" class="mb-2">
        <MainNav :showChooseRestaurant="authStore.restaurants.length > 1"></MainNav>
    </header>
    <router-view></router-view>


</template>

<script>
import MainNav from "@/components/MainNav";
import {useAuthStore} from "@/store/authStore";

export default {
    components: {
        MainNav
    },
    data() {
        const authStore = useAuthStore();

        return {
            authStore,
        }
    },

}
</script>

<style lang="scss">


#loader {
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 10;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center
}
</style>