import {createRouter, createWebHistory} from 'vue-router'
import {useAuthStore} from "@/store/authStore";
const routes = [
    {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "login" */'../views/LoginView.vue')
    },
    {
        path: '/home',
        name: 'home',
        component: () => import(/* webpackChunkName: "home" */ '../views/HomeView.vue')
    }
]
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

const publicPages = ['/login'];
router.beforeEach((to) => {
    const authRequired = !publicPages.includes(to.path),
        auth = useAuthStore();
    if (authRequired && !auth.username) {
        return '/login';
    }
});

export default router